<div class="video-recorder gn-bg-white">
  <p class="gn-text-lg">Video Message
    <span tooltipClass="note-tooltip" 
      placement="right"
      tooltip="Record a video message for the recipient and we'll print a QR code for them to scan with their phone and watch!" 
      class="gn-mr-4 gn-aspect-square gn-rounded-full gn-bg-blue-300 gn-text-center gn-px-2 gn-text-sm">i</span>
  </p>

  @if(!hasStream && !videoUrl){
    <div>Tap to Switch On the Camera</div>
  }
  @if(hasStream && !videoUrl){
    <div>Tap to Record (Max: 15 seconds)</div>
  }

  <!-- Hide the video feed when recording stops -->
  @if(isRecording || !videoUrl){
   <div class="gn-flex gn-items-center gn-justify-center gn-mt-4">
      <video class="gn-w-1/2 gn-bg-black camera" autoplay muted [attr.data-id]="id"></video>
    </div>
   }

  <div class="gn-flex gn-items-center gn-justify-center gn-mt-4">
    @if(!hasStream && !videoUrl){
        <button (click)="startStream()" class="gn-bg-main gn-text-white gn-px-4 gn-py-2 gn-rounded-full gn-mr-2">
          <fa-icon [icon]="faVideo" class="gn-text-white"></fa-icon>
          
          Switch On Camera
        </button>
        }
    
    @if(!isRecording && !videoUrl && hasStream){
    <button (click)="startRecording()" class="gm-w-full gn-text-black gn-bg-gray-200 gn-px-4 gn-py-2 gn-rounded-full">
        <fa-icon [icon]="faCircleIcn" class="gn-text-red-500 gn-mr-2"></fa-icon>Record
    </button>
    
    <button (click)="deleteVideo()" class="gn-bg-main gn-text-white gn-px-4 gn-py-2 gn-rounded-full gn-mr-2">
        <fa-layers class="fa-fw">
          <fa-icon [icon]="faVideo" class="gn-text-white"></fa-icon>
          <fa-icon [icon]="faSlash" class="gn-text-white"></fa-icon>
        </fa-layers>
        Switch Off
    </button>
    }
    @if(isRecording){
    <button (click)="stopRecording()" class="gn-text-black gn-bg-gray-200 gn-px-4 gn-py-2 gn-rounded-full">
        <fa-icon [icon]="faStopIcn" class="gn-text-black gn-mr-2"></fa-icon>Stop
    </button>
    }
  </div>

  <div *ngIf="isRecording" class="gn-text-center gn-mt-2">
    <p>Recording... {{ recordingTime }}s</p>
  </div>

  <div *ngIf="videoUrl" class="gn-mt-4">
    <p>Recording complete. Play the video below:</p>
    <div class="gn-flex gn-items-center gn-justify-center gn-mt-4">
        <video [src]="videoUrl" controls class="gn-w-1/2 gn-bg-black"></video>
    </div>
    <button (click)="deleteVideo()" class="gm-w-full gn-text-black gn-bg-gray-200 gn-px-4 gn-py-2 gn-rounded-full" tooltip="Deletes the file.<br/> You can record again.">
        <fa-icon class="fa-solid gn-text-red-500" [icon]="faXmarkIcn"></fa-icon>Delete
    </button>
  </div>
</div>