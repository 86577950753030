import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit, OnDestroy, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';
import { FaIconComponent, FaLayersComponent } from '@fortawesome/angular-fontawesome';
import { faCircle, faPause, faPlay, faStop, faXmark, faVideo, faSlash, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { EventEmitter } from '@angular/core';
import { v4 as uuidv4 } from 'uuid'; // Import UUID library

@Component({
  selector: 'app-video-recorder',
  templateUrl: './video-recorder.component.html',
  styleUrls: ['./video-recorder.component.sass'],
  imports: [NgClass, NgIf, FaIconComponent, FormsModule, TooltipModule, FaLayersComponent]
})
export class VideoRecorderComponent implements OnInit, OnDestroy {
  id: string; // Unique ID for each instance
  isRecording = false;
  videoUrl: string | null = null;
  recordingTime = 0; // Timer in seconds
  private mediaRecorder: MediaRecorder | null = null;
  private videoChunks: Blob[] = [];
  private recordingTimeout: any = null;
  private timerInterval: any = null;
  private stream: MediaStream | null = null; // Store the camera stream
  hasStream = false;

  public faVideo = faVideo; // Video icon
  public faSlash = faSlash; // Slash icon for striking through
  public faCircleIcn = faCircle;
  public faStopIcn = faStop;
  public faPlayIcn = faPlay;
  public faXmarkIcn = faXmark;
  public faPauseIcn = faPause; // Add the pause icon

  public videoBlob: Blob | null = null; // Store the recorded video blob
  public recordingSizeMB: number | null = null; // New property to store the size in MB

  @Output() record: EventEmitter<{blob:string, duration:number, size:number, type:string}> = new EventEmitter();

  constructor() {
    this.id = uuidv4(); // Generate a unique ID for this instance
  }

  async ngOnInit() {
  }

  async startStream() {
    try {
      // Request camera access on component initialization
      this.stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      this.hasStream = true;

      // Attach the stream to the video element
      const videoElement = document.querySelector(`video[data-id="${this.id}"]`) as HTMLVideoElement;
      videoElement.srcObject = this.stream;
      videoElement.muted = true; // Ensure the video element is muted
      videoElement.volume = 0; // Explicitly set the volume to 0
      videoElement.play();
    } catch (error) {
      console.error('Error accessing camera:', error);
      alert('Camera access is required to display the video feed.');
    }
  }

  ngOnDestroy() {
    // Stop the camera stream when the component is destroyed
    if (this.stream) {
      const tracks = this.stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  }

  async startRecording() {
    if (!this.stream) {
      alert('Camera is not available.');
      return;
    }

    try {
      this.mediaRecorder = new MediaRecorder(this.stream);

      // Collect video data chunks
      this.mediaRecorder.ondataavailable = (event) => {
        this.videoChunks.push(event.data);
      };

      this.mediaRecorder.onerror = (event) => {
        alert('Error recording video');
      };

      // Start recording
      this.mediaRecorder.start();
      this.isRecording = true;
      this.recordingTime = 0;

      // Start the timer
      this.timerInterval = setInterval(() => {
        this.recordingTime++;
      }, 1000);

      // Automatically stop recording after 15 seconds
      this.recordingTimeout = setTimeout(() => {
        this.stopRecording();
        alert('Recording stopped automatically after 15 seconds.');
      }, 15000);
    } catch (error) {
      console.error('Error starting recording:', error);
      alert('Failed to start recording.');
    }
  }

  stopRecording() {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
      this.mediaRecorder.onstop = () => {
        // Combine video chunks into a single Blob
        this.videoBlob = new Blob(this.videoChunks, { type: 'video/webm' });
        this.videoUrl = URL.createObjectURL(this.videoBlob);
        this.videoChunks = []; // Clear chunks for the next recording
      };

      this.isRecording = false;

      // Stop the video stream
      this.hasStream = false;
      if (this.stream) {
        const tracks = this.stream.getTracks();
        tracks.forEach((track) => track.stop());
        this.stream = null; // Clear the stream
      }
      if (this.videoBlob) {
        //this.recordingSizeMB = (this.videoBlob.size / (1024 * 1024)).toFixed(2) as unknown as number;

        const reader = new FileReader();
        reader.readAsDataURL(this.videoBlob);
        reader.onloadend = () => {
          const base64data = reader.result as string; // Base64-encoded string

          const payload = {
            fileName: 'recording.webm',
            fileData: base64data.split(',')[1] // Remove the Base64 prefix
          };
          this.record.emit({blob:payload.fileData, duration:this.recordingTime, size:this.videoBlob.size,type:'video/webm'});

        }    

      }

      // Clear the timer
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }

      // Clear the timeout
      if (this.recordingTimeout) {
        clearTimeout(this.recordingTimeout);
        this.recordingTimeout = null;
      }
    }
  }

  deleteVideo() {
    this.videoUrl = null; // Remove the recorded video URL
    if (this.stream) {
      const tracks = this.stream.getTracks();
      tracks.forEach((track) => track.stop());
      this.stream = null; // Clear the stream
    }
    this.hasStream = false;
  }

  private async restartStream() {
    try {
      // Request camera access again
      this.stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      this.hasStream = true;

      // Attach the stream to the video element
      const videoElement = document.querySelector(`video[data-id="${this.id}"]`) as HTMLVideoElement;
      videoElement.srcObject = this.stream;
      videoElement.play();
    } catch (error) {
      console.error('Error restarting camera:', error);
      alert('Failed to restart the camera.');
    }
  }
}