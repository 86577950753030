import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy, OnChanges, 
    ChangeDetectorRef, ChangeDetectionStrategy, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, FormGroup, MinLengthValidator, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { from, Observable, of, throwError, debounceTime } from 'rxjs';
import { switchMap, finalize, tap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import round from 'lodash/round';

import { isBefore, isAfter, parse, startOfDay } from 'date-fns';

import { HttpService } from 'src/app/services/http.service';
import { PaymentService } from 'src/app/services/payment.service';
import { MemoryStorage } from 'src/app/services/memory-storage.service';

import { ReCaptcha2Component } from 'ngx-captcha/lib';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgClass, CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';


declare var paypal: any;
declare var Worldpay: any;
//declare var fbq: any;

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.sass'],
    imports: [FormsModule, ReactiveFormsModule, NgxCaptchaModule, FaIconComponent, NgSelectComponent, NgClass, CurrencyPipe, TranslateModule]
})
export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('paypalPP') paypalPP: ElementRef;
    @ViewChild('worldpayFormEl') worldpayForm: ElementRef;
    @ViewChild('nativeEl') nativeRef: ElementRef;
    @ViewChild('cardEl') cardRef: ElementRef;

    @Input() vouchers: UntypedFormArray;
    @Input() company: any;
    @Input() total = 0;
    @Input() brandId: string;

    @Input() fbad: string;
    @Input() gclid: string;
    @Input() bingad: string;
    @Input() affiliateId: string;
    @Input() adId: string;
    @Input() test: string;

    @Input() sale: any;

    @Output() payment: EventEmitter<any> = new EventEmitter();

    public faTimes = faTimes;
    public dueNow = 0;
    public discounts = 0;
    public showPaymentRequest = false;
    public loading = false;
    public codeLoading = false;
    public cardHidden = true;
    public showPaypal : boolean = false;
    public form: UntypedFormGroup;
    public cardError = 'No Input';
    public appliedPromo: any;
    public directories: any = JSON.parse(this.memoryStorage.getItem('directories'));
    public params: any = JSON.parse(this.memoryStorage.getItem('params'));
    public terms: any[] = [];
    public hint: string = '';
    public reCaptchaSiteKey = '';
    public saleTypes: any[] = [];

    public captchaIsLoaded: boolean = false;
    public captchaSuccess: boolean = false;
    public captchaError: boolean = false;
    public captchaResponse?: string;
    public captchaIsReady: boolean = false;
    public captchaEnabled: boolean = false;

    public fromDate: string = '';

    public sniffDodgy: boolean = false;

    private paymentRequestInstance: any;
    private paymentButton: any;
    private paypalButton: any;
    private card: any;
    private cardHandler = this.onChange.bind(this);

    public paypalParams: {[key:string]:any} = {};
    public PPpayLater: boolean = false;


    protected aFormGroup: FormGroup;

    @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component ;

    constructor(
        private http: HttpService,
        private payments: PaymentService,
        private builder: UntypedFormBuilder,
        private toastr: ToastrService,
        private cd: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private memoryStorage: MemoryStorage,
        //private cdr: ChangeDetectorRef
    ) {
    	this.fbad = this.params.fbad;
    	this.gclid = this.params.gclid;
    	this.affiliateId = this.params.affiliateId;
    	this.bingad = this.params.bingad;
    	this.adId = this.params.adId;
        this.test = this.test ? this.test : (this.http.isTestMode?'1':'0');
    }

    ngOnInit() {
        if(this.sale && this.sale?.total_cost){
            this.total = +this.sale?.total_cost - +this.sale?.total_paid;
        }

        this.dueNow = round(this.total, 2);
        this.cardHidden = !!this.profiles.includes('paypal');

        if (this.affiliateId) this.applyPromoVoucher(this.affiliateId, false);

        if(this.sale && this.sale.vouchers){
            this.sale.vouchers.forEach(it=>{if(it.promo_id) this.sale.promo_id = it.promo_id})
            if(this.sale.promo_id){
                setTimeout(()=>this.applyPromoVoucher(this.sale.promo_id),1500)
            }
        }

        this.form = this.builder.group({
            customer_name: [this.sale?.customer_name?this.sale.customer_name:'', Validators.required],
            customer_email: [this.sale?.customer_email?this.sale.customer_email:'', [Validators.required, Validators.minLength(6)]],
            customer_tel: [this.sale?.customer_tel?this.sale.customer_tel:'', [Validators.pattern('(\\+)?[0-9]+'),Validators.required, Validators.minLength(6)]],
            card_zip: ['', Validators.required],
            code: [''],
            sale_type: [this.sale?.sale_type],
            terms: [this.sale?true:false, Validators.requiredTrue],
            subscribed: [this.sale?.subscribed || false]
        });

        this.terms = (
            this.directories.texts.terms_vouchers?.length ?
                this.directories.texts.terms_vouchers :
                this.directories.texts.terms_booking
        ).map(item => ({
            title: this.sanitizer.bypassSecurityTrustHtml((item.title || '').replace(/\n/g, '<br />')),
            text: this.sanitizer.bypassSecurityTrustHtml((item.text || '').replace(/\n/g, '<br />'))
        }));

        //this.aFormGroup = this.builder.group({recaptcha: ['',Validators.required]})
        this.form.valueChanges.pipe(debounceTime(500)).subscribe(it=>{
            this.checkDodgy(it);
            this.setHint(it);
        if(it.customer_email && it.customer_tel && it.customer_name && it.terms && !this.sale?.sale_id){
                this.makeSale().subscribe(()=>this.loading = false);
            }
            //this.cdr.detectChanges();
        })

        this.setHint(this.form.getRawValue());
        //this.checkCaptchaSettings();

        this.fromDate = new Date().toISOString();
        this.saleTypes = this.directories.sale_types.map(it=>Object.entries(it).map((val)=>({id:val[0],text:val[1]}))[0]);
    }

    ngAfterViewInit() {
        this.initPaypal();
        this.initStripe();
        this.initNative();
    }

    ngOnDestroy() {
        if (this.paymentButton) this.paymentButton.destroy();

        if (this.card) {
            this.card.destroy();
            this.card.removeEventListener('change', this.cardHandler);
        }
    }

    public get profiles(): string[] {
        return this.payments.profiles;
    }

    public get currency(): string {
        return this.company.currency || 'USD';
    }

    public setHint(data): string{
        if (this.form.get('customer_name').invalid) return this.hint = 'hints.fill_in_name';
        if (this.form.get('customer_email').invalid){
            if(this.form.get('customer_email').value.indexOf(' ')>-1){
                this.form.get('customer_email').setValue(this.form.get('customer_email').value.replace(/\s/g,''));
            }
            return this.hint =  'hints.provide_valid_email';
        } 
        if (this.form.get('customer_tel').invalid){
            if(this.form.get('customer_tel').value.match(/[^0-9]/g)>-1){
                this.form.get('customer_tel').setValue(this.form.get('customer_tel').value.replace(/[^0-9]/g,'')); 
            }
            if(this.form.get('customer_tel').value == '') return this.hint =  'Fill in phone';
            return this.hint = 'Invalid phone number';

        } 
        if (this.form.get('terms').invalid && !this.form.get('terms').value) return this.hint =  'Accept Terms';
        //if (this.captchaEnabled && !this.captchaError && !this.captchaSuccess) return this.hint =  "Complete Captcha"
        //this.captchaEnabled = false;
        if (this.cardHidden) return this.hint =  'Choose payment method';
        if (!this.cardHidden && this.cardError != null) return this.hint = 'Check Card Details'
        if (this.form.get('card_zip').invalid && !this.cardHidden) return this.hint =  'Fill in post code';
        if(!this.sale?.sale_id) return this.hint = 'buttons.pay_now';

        return this.hint =  '';
    }

    public getKey(paymentMethod): string{
        if(paymentMethod !== 'card')
            return this.payments.paypalKey;
        /*if(this.hasPayprofile('worldpay'))
            return this.payments.worldpayKey;*/
        if(this.hasPayprofile('stripe'))
            return this.payments.stripeKey;
    }

    public hasPayprofile(profile: string): boolean{
        return this.profiles.includes(profile);
    }


    public checkCaptchaSettings(){
        let enable: boolean = undefined;
        if(this.company?.cms_config?.captcha){
            let captcha = this.company.cms_config.captcha;
            if(captcha?.widgets.length > 0 && !captcha.widgets.find(it=>it == 'wc-vouchers')){
                this.reCaptchaSiteKey = undefined;
                this.captchaEnabled = false;
                return;
            }
            this.reCaptchaSiteKey = captcha.key;
            if(captcha.sniff_dodgy){
                this.sniffDodgy = true;
            }
            if(captcha.error_threshold <= 0){
                this.captchaEnabled = true;
            }else{
                this.http.get('/companies',{
                    params: {
                        target:'sales',
                        filter:'card_notes'
                    }
                }).subscribe({next:(res)=>{
                    if(res.body.count && +res.body.count > +captcha.error_threshold) this.captchaEnabled = true;
                },error:(error)=>{
                    console.log(error);
                }})
            }
        }
        //this.cdr.detectChanges();
    }

    public makeSale(): Observable<any>{

        const form = this.form.getRawValue();

        let changes= this.sale ? (form.customer_name != this.sale?.customer_name || 
                    form.customer_email != this.sale?.customer_email ||
                    form.customer_tel != this.sale?.customer_tel ||
                    form.subscribed != this.sale?.subscribed ||
                    form.sale_type != this.sale?.sale_type) : true;
        //if(this.sale?.sale_id && !this.appliedPromo?.id) return of(true)
        this.loading = true;

        if(changes && this.appliedPromo?.id && this.sale?.vouchers){
            this.sale.vouchers.forEach(it=>{
                if(it.promo_id != this.appliedPromo?.id){
                     changes = true;
                }
            })
        }

        let transaction: any = {};

        const headers: any = { 'X-API-KEY': '' };

        let params = {
            ...form,
            brand_id: this.brandId || null,
            fbad: this.fbad || '',
            gclid: this.gclid || '',
            bindad: this.bingad || '',
            affiliate_id: this.affiliateId || '',
            _tracker: {
                fbad: this.fbad || false,
                gclid: this.gclid || false,
                bindad: this.bingad || false,
                affiliate_id: this.affiliateId || false
            },
            from_date: this.fromDate,
            currency: this.currency,
            vouchers: this.vouchers.getRawValue().map(item => ({ ...item, currency: this.currency, promo_id: this.appliedPromo ? this.appliedPromo?.id : null }))
        };

        if(this.sale?.sale_id) params.sale_id = this.sale.sale_id

        return (/*this.sale && !this.appliedPromo?.id ? of(null) :*/ (this.sale? this.http.put('/sales', params, {headers:{'X-API-KEY':this.sale?.guest_key}} ) : this.http.post('/sales', params ))).pipe(tap(res => {
            if (res) this.sale = res.body.sales[0];
            this.payment.emit(this.sale);
            return of(true);
        }),
        catchError(err=>{
            //err.body
            this.toastr.error('Voucher couldn\'t be created');
            return of(null);
        }))
    }

    public makeTransaction(token?: { id: string }, paymentMethod = 'card', ev?: any, force = false): void {
        if (this.loading && !force) return;
        this.loading = true;

        let transaction: any = {};

        const form = this.form.getRawValue();
        const headers: any = { 'X-API-KEY': '' };

        (this.makeSale()).pipe(
            /*tap(res => {
                if (res) this.sale = res.body.sales[0];
            }),*/
            switchMap(() => !this.sale?.sale_id? of(null) : token || this.dueNow <= 0 ? of(token) : this.getToken(form)),
            switchMap(res => {
                if(this.payments.debug){
                    console.log('makesale token',this.sale?.sale_id, token, this.dueNow);
                    this.toastr.show([this.sale?.sale_id, token?.id, this.dueNow].join(','));
                    console.log('makesale token',res);
                    this.toastr.show(JSON.stringify(res));
                }
                if (!res) return of(null);


                let params = {
                    sale_id: this.sale?.sale_id,
                    payment_method: paymentMethod,
                    portal_ref: res?.id, //the token
                    total_amount: this.dueNow,
                    portal_uid: this.getKey(paymentMethod),
                    name: form.name,
                    email: form.email,
                    card_zip: form.card_zip,
                    note: form.note || null,
                    source: 'widget.voucher.public',
                    save: 1,
                    currency: this.currency,
                    test: this.test ?? 0,
                    _return: 'sale'
                }

                if(this.captchaEnabled){
                    params['request_3d'] = true;
                }

                return this.http.post('/transactions', params, { headers });
            }),
            switchMap(res => {
                if(this.payments.debug){
                    console.log('after transactions',res);
                    this.toastr.show(JSON.stringify(res),'after transactions');
                }
                if (!res) return of(null);

                transaction = res.body.transactions[0];

                if (res.body.sales) this.sale = res.body.sales[0];

                if (!res.body['3d_secure'] || res.body['3d_secure'].action === 'success') return of(res);

                if(this.hasPayprofile('stripe')){
                    if (ev) ev.complete('success');
                    return this.payments.stripe.handleCardAction(res.body['3d_secure'].payment_intent_client_secret);
                }
            }),
            switchMap((res: any) => {
                if(this.payments.debug){
                    console.log('after 3d secure',res);
                    this.toastr.show(JSON.stringify(res),'after 3d secure');
                }
                if (!res) return of(null);
                if (res.error) return throwError(()=>({ type: 'card_error', message: res.error.message }));

                if (res.status) return of(res);

                return this.http.post('/transactions', { ...transaction, _return: 'sale' }, { headers });
            }),
            finalize(() => {
                this.loading = false
                //this.cdr.detectChanges();
            })
        ).subscribe({next:() => {
            if (ev) ev.complete('success');

            // eslint-disable-next-line @typescript-eslint/dot-notation
            window['dataLayer'].push({
                event: 'conversion_voucher',
                conversion_type: 'voucher',
                order_id: `${this.sale.sale_id}_${transaction.transaction_id}`,
                value: this.sale.total_cost,
                label: 'voucher',
                currency: this.sale.currency,
                category: 'conversion',
                action: 'submitted'
            });

/*            if (typeof fbq === 'function') {
                fbq('track', 'Purchase', {
                    value: this.sale.total_cost,
                    currency: this.sale.currency,
                    content_category: 'voucher',
                    content_type: 'product'
                });
            }*/

            this.loading = false;

            //if(+this.sale.total_pending + +this.sale.total_paid > 0){
                this.sale.payment_made = true;
            //}
            this.payment.emit(this.sale); 
            
            //this.cdr.detectChanges();
        }, error:(err) => {
            this.loading = false;
            console.log('error',err);
            if(this.payments.debug){
                this.toastr.show(JSON.stringify(err),'error');
            }
            if (ev) ev.complete('fail');

            this.toastr.error('Payment wasn\'t successful, please check your details and try again');
            //this.cdr.detectChanges();
        }});
    }

    public applyPromoVoucher(promo: any=null, showError=true): void {
        if (this.codeLoading || !promo && !this.form.get('code').value) return;
        this.codeLoading = true;

        if(!promo) promo = this.form.get('code').value;

        const invalid = { promo: false };

        let params :{[key:string]:string} = { promocode: promo, no_intercept_response: '1' }
        if(this.sale?.promo_id){
            delete params.promocode;
            params.promo_id = this.sale?.promo_id;
        }

        this.http.get('/promos', {
            params: params
        }).pipe(
            tap(res => {
                const appliedPromo = this.applyPromo(res.body.promos[0],showError);

                if (!appliedPromo) return invalid.promo = true;

                this.appliedPromo = appliedPromo;
            }),
            finalize(() => {

                if (invalid.promo && showError) this.toastr.error('Invalid promo code');

                this.codeLoading = false;
                this.form.get('code').setValue('');
            })
        ).subscribe({next:()=>{
            if (this.dueNow <= 0) this.form.get('card_zip').disable();
        }, error:()=> {if(showError)this.toastr.error('Invalid promo code');}});
    }

    public removePromo(): void {
        this.appliedPromo = null;
        this.dueNow = round(this.total, 2);

        this.form.get('card_zip').enable();
        this.updateCardAmount();
    }

    private applyPromo(promo: any, showError = true): any {
        let redemptionFrom = promo.start_date ? promo.start_date : null;
        let redemptionTo = promo.end_date ? promo.end_date : null;

        if(redemptionFrom) redemptionFrom = parse(redemptionFrom,'yyyy-MM-dd',new Date());
        if(redemptionTo) redemptionTo = parse(redemptionTo,'yyyy-MM-dd',new Date());

        // if (+promo.sales !== 1) return;
        let now = startOfDay(new Date());
        if (redemptionFrom && isBefore(now, redemptionFrom)) return;
        if (redemptionTo && isAfter(now, redemptionTo)) return;

        let invalidAffiliate = false;
        let invalidBrand = false;

        if(this.affiliateId === undefined)
                this.affiliateId = ''

        if(promo.brand_ids && promo.brand_ids.length > 0 && 
            promo.brand_ids.find(el => el==this.brandId || el=='any') === undefined){
                invalidBrand = true;
        }

        let aff = this.affiliateId.toLowerCase();
        if(aff == 'tap' || aff == 'ap' || aff == 'gb') this.affiliateId = '';

        if(promo.affiliate_ids.length == 0 && this.affiliateId != '' && promo.type != 'affiliate'){
                invalidAffiliate = true;
        }else if(promo.affiliate_ids.length > 0 && promo.affiliate_ids.find(el => el==this.affiliateId || el=='none' && this.affiliateId=='') === undefined){
                invalidAffiliate = true;
        }

        if(invalidAffiliate){
                if(showError){
                    let msg = 'Not valid with any other offer. '+this.affiliateId;
                    this.toastr.error(msg);
                }
            return;
        }

        if(invalidBrand){
            if(showError){
                let msg = `Not valid for brand (${this.brandId})`;
                this.toastr.error(msg);
            }
        return;
    }

    let wrap_options = 0;
    let mail_services = 0;

    let wrap_options_found = 0;
    let mail_services_found = 0;

        const total = this.vouchers.getRawValue().map(val => {
            const wrapping = this.directories.wrap_options.find(item => item.wrap_option_id === val.wrap_option_id);
            const mailing = this.directories.mail_services.find(item => item.mail_service_id === val.mail_service_id);
            return { ...val, wrapping_cost: +wrapping?.price, mailing_cost: +mailing?.price };
        }).reduce((acc, item) => {
            let spend_allowed = true;

            if(Number(promo.min_price) > 0.00 && Number(item.value)< Number(promo.min_price) ||
               Number(promo.max_price) > 0.00 && Number(item.value) > Number(promo.max_price)) spend_allowed = false;

            if (
                !spend_allowed ||
                (item.preset && +promo.voucherpresets === 0) ||
                (!item.preset && +promo.sales === 0)
            ){
                return acc + item.value + item.mailing_cost + item.wrapping_cost;
            }

            let total = item.value;


            let newWrappingCost = item.wrapping_cost;
            let newMailingCost = item.mailing_cost;

            if(promo.wrap_option_ids && promo.wrap_option_ids.length > 0){
                wrap_options++;
                if(promo.wrap_option_ids.find(el => el==item.wrap_option_id || el=='any') === undefined){
                    return acc + total + newWrappingCost + newMailingCost; //no discount
                }
                wrap_options_found++;
            }

            if(promo.mail_service_ids && promo.mail_service_ids.length > 0){ 
                mail_services++;
                if(promo.mail_service_ids.find(el => el==item.mail_service_id || el=='any') === undefined){
                    return acc + total + newWrappingCost + newMailingCost; //no discount
                }
                mail_services_found++;
            }

            newWrappingCost = item.wrapping_cost - +promo.wrap_discount;
            newMailingCost = item.mailing_cost - +promo.mail_discount;


            if (promo.promo_fixed) total -= +promo.promo_fixed;
            else if (promo.promo_percent) total *= (100 - +promo.promo_percent) / 100;

            if (total < 0) total = 0;
            if (newWrappingCost < 0) newWrappingCost = 0;
            if (newMailingCost < 0) newMailingCost = 0;
            return acc + total + newWrappingCost + newMailingCost;
        }, 0);


        if(mail_services_found == 0 && mail_services > 0){
            this.toastr.error('Does not apply to this wrapping option');
        }

        if(wrap_options_found == 0 && wrap_options > 0){
            this.toastr.error('Does not apply to this delivery option');
        }


        let extra_msg = '';
        if (total === this.total){
            if(promo?.min_price && +promo?.min_price > 0){
                let cpt = this.vouchers.getRawValue().filter(it=>it.value >= +promo?.min_price).length;
                if(cpt == 0){
                    extra_msg = 'Min Value : '+promo?.min_price;
                    this.toastr.error(extra_msg);
                }
            }
            if(!extra_msg && promo?.wrap_discount && promo?.wrap_discount > 0){
                this.toastr.error('Delivery discount requires postal delivery. Please select a postal delivery.');
            }
             return;
        }

        this.dueNow = round(total, 2);
        this.updateCardAmount();
        return { promo, id: promo.promo_id, code: promo.promocode, desc: promo.desc };
    }

    private onChange({ error }: { error: Error }): void {
        if (error) this.cardError = error.message;
        else this.cardError = null;
        this.loading = false;

        this.setHint(this.form.getRawValue())

        this.cd.detectChanges();
    }

    private initPaypal(): void {

        if (!this.profiles.includes('paypal')) return;

        if(!this.payments.paypal || typeof paypal === undefined) {
            setTimeout(()=>this.initPaypal(), 250);
            return;
        }

        if(!paypal.Buttons){
            console.log('paypal error');
            return;
        }

        this.paypalParams = {};

        if(this.brandId == '17' && this.currency == 'GBP'){
            this.PPpayLater = true;
            
            let div = document.createElement('div');
            div.setAttribute('data-pp-message',''); 
            div.setAttribute('dpp-style-layout','text'); 
            div.setAttribute('pp-style-logo-type','inline'); 
            div.setAttribute('data-pp-amount',''+this.dueNow) ;
            div.setAttribute('data-pp-placement','payment'); 
            //div.setAttribute('data-pp-placement-type','120x90'); 
            this.paypalPP.nativeElement.html ='';
            this.paypalPP.nativeElement.appendChild(div);

            //payment_params['enable_funding']='paylater';
        }else{
            this.paypalParams['disable-funding']='credit';
        }

        this.paypalParams.purchase_units = [{
            amount: { value: this.dueNow || 0 },
            description: this.sale?.sale_id
        }]


        this.paypalButton = paypal.Buttons({
            createOrder: (_, actions) => {
                this.loading = true;
                if(!this.sale?.sale_id){
                    this.form.markAllAsTouched();
                    return;
                }


                this.updatePaypalParams(); //get current amount

                return actions.order.create({...this.paypalParams,
                    //intent:'AUTHORIZE', //CAPTURE
                    //enable_funding:'paylater',
                });
            },
            onApprove: (data, actions) => {

                from(actions.order.authorize()).subscribe({next:res => {
                    this.makeTransaction({ id: data.orderID }, 'paypal', null, true);
                },error:(err)=>{
                    if (err.message) this.toastr.error("Paypal Payment failed Authentication.\n. If the problem persists, log into your paypal account, and check your account doesn`'t need validating.");
                    this.loading = false;
                    this.http.post('/note',{'sale_id':this.sale?.sale_id,'message':`Paypal Payment failed Authentication`,'viewed':1,'type':'admin'})
                    err.message = `Paypal Payment failed Authentication:\nbooking_id ${this.sale?.sale_id} ${err.message}`;
                    throwError(()=>err).subscribe()
                }});
            },
            onCancel: () => this.loading = false,
            onError: error => {
                this.loading = false;

                console.log(error);
                //this.cdr.detectChanges();
            }
        }).render('#rezbot-voucher-paypal-el')
        this.showPaypal = true;

        setTimeout(()=>this.checkPaypalAndRemove(),500); //no longer does anything
    }

    private checkPaypalAndRemove(cpt:number = 0, too_many=false){
        if(cpt == 0){
            this.showPaypal = false;
        }
        if(cpt > 5) return;
        if(Object.values(document.getElementsByTagName('iframe')).filter(it=>it.title=='PayPal').length == 1){
            setTimeout(()=>this.showPaypal = true);
            return;
        }
        if(Object.values(document.getElementsByTagName('iframe')).filter(it=>it.title=='PayPal').length > 1){
            if(!too_many){
                console.log('too many iframes !',Object.values(document.getElementsByTagName('iframe')).filter(it=>it.title=='PayPal').length);
                setTimeout(()=>this.checkPaypalAndRemove(cpt+1,true),3000);
                return;
            }else{
                //too many iframes. Paypal is broken. remove the button.
                //document.getElementById('rezbot-voucher-paypal-el').parentElement.remove();
                paypal ??= undefined;
                this.payments.profiles = this.payments.profiles.filter(it=>it!='paypal'); //remove paypal from profiles
                
               return;
            }   
            
        }
        setTimeout(()=>this.checkPaypalAndRemove(cpt+1),300);
    }


    private initStripe(): void {
        if (!this.profiles.includes('stripe')) return;

        if(!this.payments?.stripe){
            setTimeout(()=>this.initStripe(),500); return;
        }

        this.card = this.payments.elements.create('card', {
            style: {
                base: {
                    lineHeight: '18px',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    '::placeholder': {
                        color: 'rgba(110, 110, 110, 0.6)',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '18px'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            },
            hidePostalCode: true
        });

        this.card.mount(this.cardRef.nativeElement);

        this.card.addEventListener('change', this.cardHandler);
    }

    private updatePaypalParams(): void{
        this.paypalParams.purchase_units = [{
            amount: { value: this.dueNow || 0 },
            description: this.sale?.sale_id
        }]
    }

    private updateCardAmount(): void{
        let update = {total: {
            label: 'Vouchers',
            amount: Math.round(this.dueNow * 100)
            }}
        /*if(this.card){
            this.card.update(update); //not needed
        }*/
        if(this.paymentRequestInstance){
            this.paymentRequestInstance.update(update);
        }

        this.updatePaypalParams();
    }

    private async initNative(): Promise<void> {
        if (!this.profiles.includes('stripe')) return;

        if(!this.payments.stripe){
            setTimeout(()=>this.initNative(),500); return;
        }

        this.paymentRequestInstance = this.payments.stripe.paymentRequest({
            country: 'GB',
            currency: this.currency.toLowerCase(),
            total: {
                label: 'Vouchers',
                amount: Math.round(this.dueNow * 100),
            },
            requestPayerName: true,
            requestPayerEmail: true,
            requestPayerPhone: true
        });

        this.paymentButton = this.payments.elements.create('paymentRequestButton', {
            paymentRequest: this.paymentRequestInstance,
            style: {
                paymentRequestButton: { type: 'buy', theme: 'light', height: '45px' }
            }
        });

        this.showPaymentRequest = await this.paymentRequestInstance.canMakePayment();

        if (!this.showPaymentRequest) return;

        this.paymentButton.mount(this.nativeRef.nativeElement);

        this.paymentRequestInstance.on(this.payments.secure ? 'paymentmethod' : 'token', ev => {
            if (ev.error) {
                this.loading = false
                return ev.complete('fail');
            }

            //console.log('making transaction...');
            this.makeTransaction(ev[this.payments.secure ? 'paymentMethod' : 'token'], 'card', ev);
        });
    }

    private getToken(form: any): Observable<any> {
        if (this.dueNow <= 0) return of(null);

        if (!this.payments.secure) {
            return from(this.payments.stripe.createToken(this.card)).pipe(
                switchMap(({ token, error }: any) => {
                    if (error) {
                        this.toastr.error(error.message);
                        return throwError(() => error);
                    }

                    of(token);
                })
            );
        }

        return from(this.payments.stripe.createPaymentMethod('card', this.card, {
            billing_details: { name: form.customer_name }
        })).pipe(
            switchMap(({ paymentMethod, error }) => {
                if (error) {
                    this.toastr.error(error.message);

                    return throwError(() => error);
                }

                return of(paymentMethod);
            }),
        );
    }

    /*private promoTransaction(): Observable<any> {
        if (!this.appliedPromo) return of(null);

        return this.http.post('/bookings', {
            sale: this.sale.sale_id,
            vouchers: this.sale.vouchers.map(item => ({
                ...item,
                promo_id: this.appliedPromo?.id
            }))
        });
    }*/

    
    public handleCaptchaReset(): void {
        this.captchaSuccess = false;
        this.captchaResponse = undefined;
        //this.cdr.detectChanges();
    }
    public handleCaptchaExpire(): void {
        //this.captchaElem.reloadCaptcha();
        this.captchaSuccess = false;
        this.setHint(this.form.getRawValue());
        //this.cdr.detectChanges();
    }
    public handleCaptchaError(captchaResponse: string): void {
        //this.captchaElem.reloadCaptcha();
        this.captchaError = true;
        this.captchaResponse = 'Error';
        this.setHint(this.form.getRawValue());
        //this.cdr.detectChanges();
    }
    public handleCaptchaLoad(): void {
        this.captchaIsLoaded = true;
        //this.cdr.detectChanges();
    }
    public handleCaptchaSuccess(captchaResponse: string): void {
        this.captchaSuccess = true;
        this.captchaResponse = captchaResponse;
        this.setHint(this.form.getRawValue());
        //this.cdr.detectChanges();
    }

    public checkDodgy(data){
            /*if (changes.lang && changes.lang.currentValue) this.translate.use(changes.lang.currentValue);*/
            if(this.sniffDodgy && this.reCaptchaSiteKey && 
                data.customer_name.length > 3 && data.customer_email.length > 3){
                    let parts = data.customer_name.split(' ');
                    let name_match = parts.find(it=>data.customer_email.toLowerCase().indexOf(it.toLowerCase()) >=0)
                    if(name_match && data.customer_email.match(/\d{1,4}/) !== null){
                        this.captchaEnabled = true;
                    }
            }
        }

    public changeSaleType(): void {
        let sale_type = this.form.get('sale_type').value;
        const date = new Date();
        if(sale_type == 'christmas'){
            const xmas = new Date(date.getFullYear(),11,25);

            if(date.getMonth() >= 7 && xmas > date){
                this.fromDate = xmas.toISOString();
            }
        }else{
            this.fromDate = date.toISOString();
        }
   }

   scrollTo(el: HTMLElement) {
    console.log('a');
    el.scrollIntoView({behavior: 'smooth'});
}
}